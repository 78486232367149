.nav-container {
  position: relative;
}
.myNav {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
}
.site-moto {
  position: absolute;
  /* color: #F7567C; */
  color: #5d576b;
  top: 30px;
  left: 100px;
  font-size: 20px;
  font-weight: 600;
}
.site-moto-helper {
  white-space: nowrap;
  position: absolute;
  /* color: #F7567C; */
  color: #5d576b;
  top: 52px;
  left: 100px;
  font-size: 15px !important;
  font-weight: 600;
}
.recon-diff-logo {
  color: #5d576b;
  font-size: 20px !important;
  font-weight: 600;
}

nav {
  height: 110px;
  line-height: 90px;
}

@media screen and (max-width: 383px) {
  .left {
    display: none !important;
  }
}

@media all and (max-width: 1000px) {
  .site-moto {
    display: none !important;
  }
  /* .flash {
    display: none;
  } */
}

.sidenav li > a {
  color: #f7567c;
  font-size: 20px;
  margin-top: 50px;
}

.sidenav {
  background-color: #99e1d9;
  z-index: 9999;
}
.ham-burger{
  position: absolute;
  z-index: 1000;
  line-height: 110px !important;
  left: 5% !important;
  font-weight: 500 !important;
  font-size: 30px !important;
}
#image {
  margin-top: 20px;
  width: 48px;
  height: 48px;
}

nav .brand-logo {
  color: #f7567c;
}

nav ul a {
  font-size: 1.25rem;
  color: #f7567c;
  font-weight: bold;
}

nav a {
  font-size: 2rem;
}

nav i.material-icons {
  line-height: 90px;
  font-size: 30px;
  color: #f7567c;
}

.nav-wrapper {
  padding: 0 9%;
  background-color: #99e1d9;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

.btn-flat {
  background-color: #99e1d9;
  color: #f7567c;
  /* font-size: 1.75em; */
}

i.right {
  margin-left: 5px;
}

#s1 a {
  line-height: 60px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  color: white;
  font-weight: normal;
}

#s2 a {
  line-height: 60px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  color: white;
  font-weight: normal;
}

#s3 a {
  line-height: 60px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  color: white;
  font-weight: normal;
}

#s1 a:hover {
  background-color: rgba(153, 225, 217, 0.781);
}

#s2 a:hover {
  background-color: rgba(153, 225, 217, 0.781);
}

#s3 a:hover {
  background-color: rgba(153, 225, 217, 0.781);
}

.dropdown-content {
  background-color: #99e1d9;
  max-width: 500px;
}

.dropdown-trigger {
  width: 150px;
}

h4 {
  color: #f7567c;
}

#s1 a {
  font-size: 1 rem;
  color: #f7567c;
  font-weight: bold;
}
#s2 a {
  font-size: 1 rem;
  color: #f7567c;
  font-weight: bold;
}
#s3 a {
  font-size: 1 rem;
  color: #f7567c;
  font-weight: bold;
}

#modal2 {
  max-width: 500px;
}
h6 {
  font-size: 1.15rem;
  line-height: 110%;
  margin: 0.7666666667rem 0 0.46rem 0;
}