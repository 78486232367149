:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}
p {
  color: #5d576b;
}
::placeholder { 
  color: #5d576b4d;
}
html, body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}
ul {
  list-style-type: none;
}
.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.justify-content-between {
  justify-content: space-between;
}
.ml-2 {
  margin-left: 10px;
}
.moboView {
  display: none;
}
.deskView {
  display: block;
}
@media only screen and (max-width: 600px) {
.moboView {
  display: block;
}
.deskView {
  display: none;
}
}
.linear-activity {
  overflow: hidden;
  height: 4px;
  background-color: #F7567C;
  margin:auto;
  width: 35%;
  margin-top: 12.5%;
  margin-bottom: 12.5%;
}

.indeterminate {
  position: relative;
  width: 100%;
  height: 100%;
}

.indeterminate:before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: rgb(247, 208, 217);
  animation: indeterminate_first 1.5s infinite ease-out;
}

.indeterminate:after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: rgb(247, 208, 217);
  animation: indeterminate_second 1.5s infinite ease-in;
}

@keyframes indeterminate_first {
  0% {
      left: -100%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
      left: -200%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}

  #send-suggestion{
    color: #F7567C;
  }

  .container:hover{
    color: #F7567C;
  }

  .waggle:hover {
    animation-play-state: paused;
  }

  @keyframes pulse {
    0% {
      transform : scale(1);
    }
    70% {
      transform : scale(1);
      box-shadow: 0 0 0 20px #99e1d95e;
    }
      100% {
        transform : scale(1);
      box-shadow: 0 0 0 0 #99e1d95e;
    }
  }


  @keyframes waggle {
    0% {
      transform: none;
    }
    10% {
      transform: rotateZ(-15deg) scale(1.1);
    }
    12% {
      transform: rotateZ(20deg) scale(1.1);
    }
    13.5% {
      transform: rotateZ(-10deg) scale(1.1);
    }
    15% {
      transform: rotateZ(10deg) scale(1.1);
    }
    16.5% {
      transform: rotateZ(-7deg) scale(1.1);
    }
    17% {
      transform: rotateZ(0) scale(1.1);
    }
    20% {
      transform: rotateZ(0) scale(1);
    }
    100% {
      transform: rotateZ(0) scale(1);
    }

  }
  .container-pulse {
    width: 100px;
    height: 100%;
    /* margin: 0 auto 0; */
    -webkit-perspective: 1000;
    perspective: 1000;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #fff;
  }
  
  .pulse-button {    
    background-color: #99E1D9;
    color: #F7567C;
    font-weight: bold;
    font-size: 1.25rem;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    display: block;
    width: 100px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: -1px;
    border: none;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(#5a99d4, .5);
    /* animation: pulse 1.5s infinite; */
    /* -webkit-animation: pulse 1.5s infinite; */
  }
  .pulse-button:hover {
    /* animation: none; */
    /* -webkit-animation: none; */
  }

  .feedback {
    position: fixed;
    bottom: 10px;
    left: 50px;
    z-index: 10000;
    background: white;
    transition: all 3s;
  }

  .feedback .form {

    padding: 15px;
    width: 225px;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  }

  .feedback .form textarea {
    margin: 0;
    height: auto;
    padding: 0.5rem 0.75rem;
    display: block;
    max-width: 95%;
    border: 1px solid #d2ddec;
    background-clip: padding-box;
    color: #12263f;
    background-color: #fff;
    border-radius: 0.375rem;
    overflow: auto;
    resize: vertical;
    height: 62px;
    font-size: 0.9em;
  }

  .feedback .form button {
    display: block;
    width: 95%;
    color: #F7567C;;
    cursor: pointer;
    background-color: #99E1D9;
    border-color: #99E1D9;;
    font-weight: bolder;
    line-height: 1.75;
    margin-top: 5px;
    border-radius: 0.25rem;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
  }

  .feedback .form textarea:focus {
    color: #12263f;
    background-color: #fff;
    border-color: #99E1D9;
    outline: 0;
    box-shadow: transparent;
  }

  .waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    vertical-align: middle;
    z-index: 1;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
}

.my-btn{
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}
.my-btn {
  text-decoration: none;
  color: #fff;
  background-color: #26a69a;
  text-align: center;
  letter-spacing: .5px;
  -webkit-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out;
  cursor: pointer;
  outline: 0;
}
/* #snackbar-2 {
  visibility: hidden;
} */
#snackbar {
  visibility: hidden;
  background-color: #99E1D9;
  min-width: 250px;
  margin-left: -125px;
  padding: 5px;
  text-align: center;
  left: 50%;
  bottom: 200px;
  position: fixed;
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  z-index: 2;
}
/* #snackbar-2 {
  visibility: hidden;
  background-color: #99E1D9;
  min-width: 250px;
  padding: 5px;
  text-align: center;
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  z-index: 2;
} */

/* This will be activated when the snackbar's class is 'show' which will be added through JS */
#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
#snackbar-2.show {
  visibility: visible;
}

/* Animations for fading in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 200px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 200px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 200px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 200px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
.container-copy{
  background-color: #99E1D9;
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  z-index: 2;
}
.container-copy-2{
  background-color: #99E1D9;
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  z-index: 2;
  margin-left: 6%;
}
.container-copy-2 > p {
  color: #F7567C;
  padding: 5px;
  font-weight: bold;
  z-index: 2;
  font-size: 16px;
  font-weight: 600;
}

#snackbar > p{
  color: #F7567C;
  padding: 5px;
  font-weight: bold;
  z-index: 2;
  font-size: 16px;
  font-weight: 600;
}

.collection{
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 7px;
  padding-right: 7px;
  border: 1px solid #99E1D9;
  box-shadow: 0 0 5px  #99E1D9;
}
@media screen and (max-width: 992px) {
  .my-view {
    flex-direction: column !important;
  }
}
strong {
  font-weight: 500 !important;
}
input.invalid[type=text]:not(.browser-default){
  border-bottom: 1px solid #F44336;
  box-shadow: 0 1px 0 0 #f44336;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  color: #f7567c;
  font-size: 20px;
  margin-top: 50px;
  font-weight: 500;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
.ham-burger {
  display: none;
}
.toggle-font {
  font-size: 13px;
}
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
@media screen and (max-width: 1000px) {
  .brand-logo, .hide-on-med-and-down {
    display: none;
  }
  .ham-burger {
    display: block;
  }
  .nav-wrapper{
    padding: 0 !important;
  }
  .btn-flat {
    font-size: 12px !important;
  }
  .material-icons {
    width: 12px !important;
    font-size: 12px !important;
  }
  .recentSearches{
    font-size: 15px !important;
  }
  .toggle-font {
    font-size: 11px;
  }
}