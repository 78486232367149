.chip {
  background-color: white;
  color: #5d576b;
  display: inline-block;
    height: 32px;
    font-size: 13px;
    font-weight: 500;
    /* color: rgba(0,0,0,0.6); */
    line-height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    /* background-color: #e4e4e4; */
    margin-bottom: 5px;
    margin-right: 5px;
}
.chip>img {
  float: left;
  margin: 0 8px 0 -12px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}
#results {
  /* color: #F7567C; */
  color: #5d576b;
  font-size: 1.5rem;
  font-weight: 500;
}

td {
  color: #5d576b;
  font-size: 15px;
}

/* .switch label input[type="checkbox"]:checked + .lever:after {
  background-color: #f7567c !important;
}

.switch label input[type="checkbox"]:checked + .lever {
  background-color: #99e1d9;
} */

label {
  color: #f7567c;
  font-weight: 700;
}
#headerlink {
  font-size: 16px;
  font-weight: 500;
}

#redirectionmsg {
  font-size: small;
  font-weight: bolder;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: white;
  font-weight: normal;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  order: none;
}
tr {
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
td, th {
  padding: 15px 5px;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px;
}
/* .switch, .switch * {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.switch label {
  cursor: pointer;
}
.switch label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}

[type="checkbox"]:not(:checked), [type="checkbox"]:checked {
    position: absolute;
    opacity: 0;
    pointer-events: none;
} */

/* The switch - the box around the slider */
.switch {
  margin: -2px 10px;
  position: relative;
  display: inline-block;
  /* width: 60px; */
  height: 12px;
  width: 40px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  /* left: 4px; */
  bottom: -5px;
  background-color: #F1F1F1;
  transition: .4s;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

input:checked + .slider {
  background-color: #99e1d9;;
}

input:focus + .slider {
  box-shadow: 0 0 1px #99e1d9;;
}

input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}