.clickhere {
  width: 799px;
  height: 447px;
  top: 100PX;
  bottom: 469px;
  left: 172px;
  color: rgba(0, 0, 0, 0.87);
  cursor: move;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  text-align: center;
  background: transparent;
  border-radius: 4px;
  position: absolute;
  z-index: 99;
}

#iframe {
  width: 69%;
  /*height:500px;*/
  height: 450px;
  border: 1px solid #99e1d9;
  margin: 20px auto;
  box-shadow: 0 0 40px #99e1d9;
  display: block;
  opacity: 0.4;
}

.material-icons {
  color: #f7567c;
  height: 16px;
  vertical-align: text-bottom;
  margin-right: 5px;
  width: 16px;
  font-weight: 600;
  font-size: 16px;
}

.card {
  background-color: white;
  border: 1px solid #99e1d9;
  box-shadow: 0 0 20px #99e1d9;
  display: block;

  max-width: 600px;

  margin-top: 5%;
  min-height: 225px;
}

@media screen and (min-width: 1300px) {
  .card {
    margin-left: 200px;
  }
}

#centertext {
  margin-left: 12%;
  margin-right: 12%;
}

#openinnew {
  /*color: #F7567C;*/
  color: black;
  height: 48px;
  width: 48px;
}

#imageofintranet {
  width: 48px;
  height: 48px;
}

p.col {
  line-height: -10px;
}
.card-content {
  /*color: #F7567C;*/
  color: black;
  font-weight: 600;
}

span.card-title.row {
  text-align: center;
  align-content: center;
}

.col {
  padding-right: 0.1rem;
  padding-left: 0.1rem;
}

.material-icons {
  line-height: 16px;
}

a {
  /*color: #F7567C;*/
  color: #f7567c;
  font-weight: 600;
}

html,
body {
  max-width: 100%;
}

table {
  /* margin-left: 150px; */
}
