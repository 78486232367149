.show {
  display: inherit;
}

.hide {
  display: none;
}

app-intro-text {
  margin-top: 10px;
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#newsnackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  font-weight: bolder;
  text-align: center; /* Centered text */
  border-radius: 10px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  transform: translateX(-50%);
  bottom: 75px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#newsnackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadeins 0.5s, fadeouts 0.5s 10s;
  animation: fadeins 0.5s, fadeouts 0.5s 10s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadeins {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 75px;
    opacity: 1;
  }
}

@keyframes fadeins {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 75px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeouts {
  from {
    bottom: 75px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeouts {
  from {
    bottom: 75px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}