.features-info-container {
  margin-top: 3%;
  margin-bottom: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #5d576b;
}
.on-svg-hover {
  transform: scale(1.5);
  transition: ease-in-out 0.75s;
}
.on-svg-leave {
  transform: scale(1);
  transition: ease-in-out 1s;
}
.page-heading-ft {
  text-align: center;
}
.inner-features-container {
  margin-top: 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-width: 40%;
  max-width: 760px;
}

a {
  color: inherit;
}
.one-box {
  cursor: pointer;
  max-width: 350px;
  width: fit-content;
  height: 180px;
  margin: 20px;
  border: 2px solid #99e1d9;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box-svg {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.box-svg-title {
  color: #5d576b;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
}

.box-svg-text {
  font-size: 12px;
  text-align: center;
  max-width: 320px;
  color: #5d576b !important;
  font-weight: 400 !important;
}
