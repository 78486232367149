#safe {
  background-color: rgb(104, 240, 104);
  height: 36px;
  line-height: 36px;
  font-size: 15px;
  color: #000;
}

#notsafe {
  background-color: red;
  height: 36px;
    line-height: 36px;
    font-size: 15px;
    color: #000;
}

.chip {
  height: 36px;
  line-height: 36px;
  font-size: 13px;
  /* color: black; */
}

.noConnectionMSG {
  margin-top: 60px;
  text-align: center;
}
.wrong {
  font-family: monospace;
  text-align: center;
  line-height: 1.5em;
  font-size: 1.5em;
  color: #5d576b;
  font-weight: bolder;
}
.wrong:after {
  content: "{º.º}";
  animation: oops 3s linear infinite;

  animation: name duration timing-function delay iteration-count direction
    fill-mode;
}
.issuemsg {
  text-align: center;
  line-height: 1.5em;
  font-size: 1.2em;
  color: #5d576b;
}
.intranetstress {
  color: #f7567c;
  font-weight: bolder;
}
.issuemsg:after {
  content: " Our servers could not reach the url.....";
}

@keyframes oops {
  0% {
    content: "{º.º}";
  }
  25% {
    content: "{·.·}";
  }
  95% {
    content: "{·.·}";
  }
  100% {
    content: "{º.º}";
  }
}

.intranetmsg {
  color: #5d576b;
  text-align: center;
  line-height: 1.5em;
  font-size: 1.2em;
}
