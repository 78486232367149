*{
    margin: 0;
    padding: 0;
  }
  
  .about-wrap{
    min-height: 80vh;
    max-height: fit-content;
  }
  
  #intro-container{
    margin-top: 3%;
    width: 45%;
  }
  
  #saurabh-intro{
    float: left;
  }
  
  #saurabh-image{
    float: right;
    margin-left:45px
  }
  
  .individual-matter{
    width: 45%;
    text-align: justify;
    margin-top: 25px;
  }
  
  .page-heading{
    font-size: 2rem;
    display: flex;
    justify-content: center;
    margin-top: 1%;
    /* transform: translate(-10% , 0%);  */
    color: #F7567C;
    transform: translate(-5% , 0%);
  }
  
  .main-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #5D576B;
    font-weight: 400;
    font-weight: normal;
    text-align: justify;
    transform: translate(-5% , 0%);
  }
  
  
  @media all and (max-width: 900px){
  
    .page-heading{
      font-size: 4vw;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      transform: translate(0%,0%);
    }
  
    #saurabh-image{
      float: left;
      margin: 0 10px 0 0;
    }
  
  
    .main-container{
      transform: translate(0%,0%);
    }
  
    #intro-container{
      font-size: 3vw;
      width: 65%;
    }
  
    .individual-matter{
      width: 65%;
      font-size: 3vw;
    }
  
    i {
      font-size: 3vw;
      font-weight: 600;
    }
    a {
      font-size: 3vw;
      font-weight: 600;
    }
  }
  

/* form starting stylings ------------------------------- */
.group 			  { 
  position:relative; 
}
input 				{
  font-size:18px;
  padding:10px 10px 10px 5px;
  display:block;
  width:400px;
  border:none;
  border-bottom:2px solid #99E1D9;
}
@media screen and (max-width: 992px) {
  input 				{
    width:180px;
  }
}
input:focus 		{ outline:none; }
/* active state */
input:focus ~ label, input:valid ~ label 		{
  top:-20px;
  font-size:14px;
  color:#5264AE;
}

/* BOTTOM BARS ================================= */
.bar 	{ position:relative; display:block; width:400px; }
@media screen and (max-width: 992px) {
  .bar 	{ width:180px; }

}
.bar:before, .bar:after 	{
  content:'';
  /* height:2px;  */
  width:0;
  bottom:1px; 
  position:absolute;
  background:#5264AE; 
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}
.bar:before {
  left:50%;
}
.bar:after {
  right:50%; 
}

/* active state */
input:focus ~ .bar:before, input:focus ~ .bar:after {
  width:50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position:absolute;
  height:60%; 
  width:100px; 
  top:25%; 
  left:0;
  pointer-events:none;
  opacity:0.5;
}

/* active state */
input:focus ~ .highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}