.flex-container {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 10px;
  }
  
  input:focus {
    border-bottom: 1px solid #99E1D9 !important;
    box-shadow: 0 1px 0 0 #99E1D9 !important;
  }
  .material-icons.active {
    color: #99E1D9;
  }
  .validate.active{
    color: #99E1D9;
  }
  input:-internal-autofill-selected {
    background-color: rgb(255, 255, 255) !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
  }
  
  .btn{
    background-color: #99E1D9;
    color: #F7567C;
    font-weight: bold;
    font-size: 1.25rem;
    width: 150%;
    width: 100px;
  }
  
  .changeBackground{
    background-color: #F7567C;
    color: #99E1D9;
  }
  /*
  .input-field.col.s1 {
    padding-left: 0.1rem;
  }
  */
  .row{
    margin-left: auto;
  }
  /*
  .row .col {
  
    padding: 0.01rem;
    padding-top: 0px;
    padding-right: 0.01rem;
    padding-bottom: 0px;
    padding-left: 0.01rem;
    min-height: 1px;
  }
  */
  /*
  i.material-icons {
    position: relative;
    left: 300px;
    line-height: 50px;
    font-size: 33px;
  }
  */
  /*
  .row{
    width: 1000px;
  }
  */
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  
  input:not([type]), input[type=text]:not(.browser-default), input[type=password]:not(.browser-default), input[type=email]:not(.browser-default), input[type=url]:not(.browser-default), input[type=time]:not(.browser-default), input[type=date]:not(.browser-default), input[type=datetime]:not(.browser-default), input[type=datetime-local]:not(.browser-default), input[type=tel]:not(.browser-default), input[type=number]:not(.browser-default), input[type=search]:not(.browser-default), textarea.materialize-textarea {
    font-size: 20px;
    color: #5D576B;
  }
  
  
  #thebox{
    max-width: 400px;
    margin-right: 5px;
  }
  /*
  .prefix{
    padding-right: 100px !important;
  }
  */
  
  
  
  
  
  
  
  
  
  
  
  .hide-dropdown{
    display: none;
  }
  
  .dropdown-wrapper {
    position: relative;
    max-width: 95px;
    width: 100%;
  }
  
  .dropdown-inner {
      display: flex;
      margin-top: -2px;
      flex-direction: column;
    /*width: 100px;*/
    width: inherit;
    position: absolute;
      left: 0;
    top: 8px;
    background-color: white;
      clip-path: polygon(0 0, 100% 0, 100% 36px, 0 36px);
      transition: .15s ease;
  }
  /*clip-path: polygon(0 0, 100% 0, 100% 40px, 0 40px);*/
  .dropdown-wrapper:hover .dropdown-inner {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  
  .after-drop-down-selection {
    clip-path: polygon(0 0, 100% 0, 100% 36px, 0 36px);
  }
  
  .dropdown-inner:after {
      content: '';
      position: absolute;
      top: 10px;
      right: 6px;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      background: linear-gradient(to bottom right, transparent 0%, transparent 50%, #F7567C 50%, #F7567C 100%);
      transition: .15s ease;
  }
  
  .dropdown-wrapper:hover .dropdown-inner:after {
      transform: translateY(5px) rotate(-135deg);
  }
  /*12 1 6 */
  .dropdown-item {
      font-size: 18px;
      display: flex;
      align-items: center;
    width: auto;
    padding: 4px 1px;
      cursor: pointer;
      transition: .3s ease;
  }
  
  .dropdown-item.-is-active {
      order: -1;
  }
  
  .dropdown-item:hover {
      background-color: rgba(0, 0, 0, 0.13);
  }
  
  /*
  .dropdown-item .spanclass {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }
  */
  .spanclass{
    position: relative;
    left: 14px
  }
  .title {
    font-size: 30px;
    text-align: center;
  }